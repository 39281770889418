import { Component, Input, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'ocf-highlight-text',
    template: ` <div *ngIf="umbracoText" [innerHTML]="parseText(umbracoText)"></div> `,
    standalone: false
})
export class TextHighlightComponent {
  private static regexBold = /\*([.*?\S]*)?\*/gm; // 'matches *this* text'
  private static regexItalic = /_([.*?\S]*)?_/gm; // 'matches _this_ text'

  @Input()
  umbracoText: SafeHtml = '';

  constructor(private sanitizer: DomSanitizer) {}

  /** Replaces '*' and '_' with HTML's bold and italic tags */
  parseText(text: SafeHtml): string {
    return this.sanitizer
      .sanitize(SecurityContext.HTML, text)!
      .replace(TextHighlightComponent.regexBold, '<strong>$1</strong>')
      .replace(TextHighlightComponent.regexItalic, '<em>$1</em>');
  }
}
